/* eslint-disable max-len */
export default {
    "Together": "Zusammen",
    "against rare": "gegen seltene",
    "disease": "Krankheiten",
    "Your engagement": "Dein Engagement",
    "Run & course": "Lauf & Strecke",
    "Organizer": "Organisator",
    "Sponsors": "Sponsoren",
    "Registration deadline by 05. Mai 2020 / 23:59":
        "Anmeldeschluss 04. Mai 2021 / 23:59 Uhr",
    "Wednesday, 06. Mai 2020 / 16:30 - 20:30":
        "Mittwoch, 05. Mai 2021 / 16:30 - 20:30 Uhr",
    "I'll run": "Ich laufe",
    "I'll sponsor": "Ich sponsore",
    "+442 meters of height difference from the bottom to the top station of the Biel-Magglingen cable car on the shortest route.":
        "+442 Höhenmeter von der Tal- zur Bergstation der Biel-Magglingen-Bahn auf der kürzesten Strecke.",
    "An exceptional commitment on an exceptional route for an exceptional purpose!":
        "Ein aussergewöhnlicher Einsatz auf einer aussergewöhnlichen Strecke für einen aussergewöhnlichen Zweck!",
    "Register": "Mitmachen",
    "Who can participate?": "Wer kann teilnehmen?",
    "Sponsoring": "Sponsoren",
    "In favour of": "Zugunsten",
    "Everyone who feels fit enough to complete the course one or more times; running, hiking, walking - time is not an issue. The descent is done by train for relaxation.":
        "Alle, die sich fit genug fühlen die Strecke ein oder mehrere Male zu bewältigen; Rennen, Wandern, Spazieren – die Zeit spielt keine Rolle. Die Talfahrt wird zur Erholung mit der Bahn zurückgelegt.",
    "Each runner acquires her or his own sponsors. The sponsors can set an amount per vertical meter, per course or a flat rate for participation.":
        "Jede Läuferin und jeder Läufer akquiriert die eigenen Sponsoren. Die Sponsoren können pro Höhenmeter, pro Strecke oder pauschal für die Teilnahme einen Betrag festsetzen.",
    "The aim of the run is to generate money to fight rare diseases and to support those affected and their families. Rare diseases are diseases that occur only sporadically; in Switzerland, for example, there are often fewer than 20 people affected. In the case of such diseases there is usually a lack of specific medication for treatment and the insurance question is often unresolved, which often leads to personal and financial hardship for the affected and their relatives.":
        "Das Ziel des Laufes ist es, Geld zur Bekämpfung von seltenen Krankheiten und zur Unterstützung von Betroffenen und deren Angehörigen zu generieren. Seltene Krankheiten sind Krankheiten, die nur vereinzelt vorkommen; in der Schweiz z.B. oft unter 20 Betroffene. Bei solchen Krankheiten fehlen meistens spezifische Medikamente zur Behandlung und die Versicherungsfrage ist häufig ungelöst, was die Betroffenen und ihre Angehörigen oft in persönliche und finanzielle Nöte bringt.",
    "Event sponsors": "Anlasssponsoren",
    "Many thanks for your support!": "Vielen Dank für deine Unterstützung!",
    "Runners": "Läuferinnen & Läufer",
    "Registration deadline": "Anmeldeschluss",
    "Newsletter": "Newsletter",
    "Subscribe": "Abonnieren",
    "Menu": "Menü",
    "My +442": "My +442",
    "Follow us": "Follow us",
    "Made with lots of": "Made with lots of",
    "and": "and",
    "in Biel/Bienne by": "in Biel/Bienne by",
    "Smartfactory GmbH.": "Smartfactory",
    "I run": "Ich bin",
    "too": "dabei",
    "Login form": "Anmeldeformular",
    "Please enter your e-mail address below to receive your one time password per e-mail.":
        "Bitte gib unten deine E-Mail Adresse an, um ein Passwort per E-Mail zu erhalten.",
    "E-Mail address": "E-Mail Adresse",
    "e-mail@example.com": "e-mail@beispiel.com",
    "Send one time password": "Passwort senden",
    "Thank you. We have just sent you an e-mail.":
        "Vielen Dank! Dein Passwort wurde per Mail versendet.",
    "Click on the link you have received or enter the registration code below.":
        "Klicke auf den Link oder gib das Passwort unten ein.",
    "error": "Fehler",
    "success": "Erfolgreich",
    "warning": "Warnung",
    "Your access and refresh tokens are expired, please login again.":
        "Deine Sitzung ist abgelaufen, bitte melde dich erneut an.",
    "Your access token could not been refreshed, please login again. ":
        "Deine Sitzung konnte nicht erneuert werden, bitte melde dich erneut an.",
    "Your access token has been refreshed successfully.":
        "Deine Sitzung wurde erfolgreich erneuert.",
    "Thank you for your login request, you should receive an e-mail with your registration code.":
        "Vielen Dank für deine Anmeldung, du erhältst in Kürze eine E-Mail mit deinem Passwort.",
    "We sent the e-mail again, you should receive an e-mail with your registration code.":
        "Du erhältst in Kürze eine E-Mail mit deinem Passwort.",
    "Something went wrong, please check your information and try again later.":
        "Etwas ist schiefgelaufen, bitte überprüfe deine Angaben oder versuche es später erneut.",
    "Something went wrong, please try again later.":
        "Etwas ist schiefgelaufen, versuche es später erneut.",
    "Please update your profile information.": "Bitte ergänze dein Profil.",
    "Save your information": "Informationen speichern",
    "Firstname": "Vorname",
    "Lastname": "Nachname",
    "Thank you for completing your profile information.":
        "Vielen Dank für die Ergänzung deines Profils.",
    "Dashboard": "Dashboard",
    "Welcome! Your login was successful.":
        "Willkommen! Du hast dich erfolgreich angemeldet.",
    "Registration code": "Passwort",
    "Login": "Login",
    "Not receiving the e-mail?": "Du hast keine E-Mail erhalten?",
    "Click here.": "Erneut senden.",
    "Oh no. This page does not exist.": "Oh nein, diese Seite existiert nicht.",
    "Hello {name},": "Hallo {name} !",
    "Welcome to your 442 board.": "Willkommen bei Ihrem +442 Board.",
    "Logout": "Logout",
    "My run": "Mein Lauf",
    "My sponsoring": "Meine Sponsorings",
    "Thank you for completing your runner information.": "Vielen Dank",
    "Please complete your runner information:":
        "Bitte vervollständige deine Informationen:",
    "I confirm that I am fit enough to run.":
        "Ich bestätige, dass ich gesundheitlich fit genug bin, um den Sponsorenlauf zu absolvieren und melde mich hiermit verbindlich an.",
    "City": "Ort",
    "Upload your profile image:": "Lade dein Profilbild hoch:",
    "Send runner information:": "Aktualisiere deine Informationen:",
    "Send runner information": "Speichern",
    "Search": "Suchen",
    "Sponsor": "Sponsern",
    "show more": "mehr anzeigen",
    "no runners found": "Es wurden keine Läufer/in gefunden",
    "Your invoicing address": "Deine Rechnungsadresse",
    "You support the following runners:":
        "Du unterstützt die folgenden Läufer/innen:",
    "Looks like you didn't create any sponsoring yet.":
        "Zurzeit unterstützt du noch keine/n Läufer/in mit einem Sponsoring. Klicke auf «Neues Sponsoring», um jetzt ein Sponsoring zu erstellen.",
    "The sponsorings with a «fixed amount» are only activated when a runner completes their first lap.":
        "Sponsorings mit dem Modus «Fixer Betrag» werden erst aktiviert, sobald der Läufer / die Läuferin die erste Runde absolviert hat.",
    "Do you have a problem?": "Gibt es ein Problem?",
    "If you would like to revoke a sponsoring you can write to:":
        "Wenn du ein Sponsoring löschen möchtest, schreib bitte an: {email}",
    "Save invoice address": "Rechnungsadresse speichern",
    "Please select a runner:": "Bitte wähle eine/n Läufer/in:",
    "Your sponsoring was added successfully!":
        "Dein Sponsoring wurde erfolgreich gespeichert – Herzlichen Dank!",
    "Your sponsoring could not been added, please try again.":
        "Dein Sponsoring konnte nicht gespeichert werden. Bitte überprüfe deine Eingaben und versuche es erneut.",
    "Please choose your sponsoring method and amount in CHF:":
        "Bitte wähle nun den Sponsoring-Modus und den Betrag:",
    "Method": "Modus",
    "Sponsoring Amount in CHF": "Sponsoringbetrag in CHF",
    "Save sponsoring": "Speichere dein Sponsoring",
    "You will be sponsoring:": "Du erstellst ein Sponsoring für:",
    "Phone": "Telefon",
    "Company": "Firma (optional)",
    "Street, house number": "Strasse, Hausnummer",
    "ZIP Code": "PLZ",
    "User avatar": "Dein Avatar",
    "Looks like you don't have any sponsors yet.":
        "Hier siehst du, wer dich beim deinem Lauf sponsert. Zurzeit hast du aber noch keine Sponsoren. Schaue später noch einmal rein...",
    "Spread the word and share the below link with your friends:":
        "Du kannst diesen Link mit deinen Freunden teilen, um sie als Sponsoren zu akquirieren:",
    "Your sponsors": "Deine Sponsoren",
    "Edit": "Bearbeiten",
    "Please complete by entering your Invoicing details below:":
        "Bitte gib die Adresse ein, an die wir nach dem Lauf die Rechnung senden können:",
    "Your invoice address was saved successfully!":
        "Deine Rechnungsadresse wurde gespeichert!",
    "Your invoice address could not been saved, please try again.":
        "Fehler beim Speichern. Bitte versuche es noch einmal.",
    "Update runner profile": "Aktualisieren",
    "Sign up as runner":
        "Hier kannst Du Dich als Läufer anmelden. Anschliessend wird dein Profil in der Läuferliste angezeigt und andere Benutzer können Dich sponsoren. In der Läuferliste wird nur dein Profilbild, dein Name und dein Wohnort angezeigt. Deine Adresse wird niemals öffentlich angezeigt.",
    "Please edit your runner information:":
        "Hier kannst du den Wohnort ändern, der in der Läuferliste angezeigt wird. Bitte beachte, dass du deine Anmeldung als Läufer nicht online löschen kannst. Bitte schreibe eine E-Mail an support@442.run, falls du deine Teilnahme als Läufer zurückziehen musst.",
    "signed up as runner": "Status: als Läufer angemeldet",
    "Per meter of altitude": "Betrag pro Höhenmeter",
    "Fixed amount": "Fixer Betrag",
    "Per round": "Betrag pro Runde",
    "Homepage": "Startseite",
    "Want to invite someone as a runner?":
        "Willst du jemanden als Läuferin oder Läufer einladen?",
    "Enter an e-mail message here and we will send an invitation:":
        "Gebe deine E-Mail Adresse ein und wir verschicken eine Einladung.",
    "Invite": "Senden",
    "Thank you! We send your friend an invitation via E-Mail.":
        "Vielen Dank! Wir haben die Einladung versendet.",
    "We could not send an E-Mail your friend, please try again later.":
        "Wir konnten deine Einladung nicht versenden, bitte versuche es später erneut.",
    "We already sent an invitation to this E-Mail address.":
        "Wir haben bereits eine Einladung an diese E-Mail Adresse versendet.",
    "realtime counter": "Spendenstand",
    "meters": "Höhenmeter",
    "rounds": "Runden",
    "money collected": "Gesammelter Betrag",
    "ticker": "Lauf-Liveticker",
    "Support me": "Unterstütze mich",
    "Please enter a number": "Bitte gib eine Nummer ein",
    "Run starts at": "Lauf startet am",
    "Run ends at": "Lauf endet am",
    "Event over": "Der Event ist vorbei",
    "The event is over, but we will be back next year":
        "Der Sponsorenlauf fand vom 5. Mai bis 2. Juni 2021 statt. Dank dem Einsatz aller Läufer*innen und Sponsor*innen war der Event ein voller Erfolg! Über die Durchführung 2022 informieren wir an dieser Stelle zu einem späteren Zeitpunkt.",
    "Thanks to all who took part": "Vielen Dank allen, die mitgemacht haben!",
    "Run starts in": "Lauf startet in",
    "Run ends in": "Lauf endet in",
    "As soon as the first laps have been run, they will be shown here":
        "Sobald die ersten Runden abgeschlossen sind, werden sie hier angezeigt.",
    "TOP": "Bergstation",
    "MIDDLE": "Mittelstation",
    "BOTTOM": "Talstation",
    "Please enter a number greater than 0":
        "Bitte wähle eine Zahl die grösser als 0 ist.",
    "It starts soon": "Bald geht's los",
    "to": "bis",
    "from": "Von",
    "Entries": "Einträge",
    "QR Scanner": "QR Scanner",
    "To the Scanner": "QR-Code Scanner öffnen",
    "This QR code is for runners of the 442.run sponsor run to scan their laps. You can get more infos about the run on 442.run.":
        "Dieser QR-Code wird für den +442 Sponsorenlauf benutzt. Mehr Infos zu diesem Lauf finden Sie auf https://442.run",
    "If you are a runner, you need to first log in and then scan the code again":
        "Wenn du ein Läufer bist, logge dich ein und scanne den QR-Code erneut.",
    "More Information": "Mehr erfahren",
    "How it works": "Wie es funktioniert",
    "If you made an error and would like to get a lap removed, please contact us at support@442.run.":
        "Gibt es ein Problem? Wurde eine Runde nicht oder falsch aufgezeichnet? Schreibe einfach an {email} und wir korrigieren den Fehler.",
    "Entry was saved": "Eintrag wurde gespeichert",
    "Entry could not be saved, try again or use the buttons below.":
        "Ein Fehler ist aufgetreten. Bitte versuche es erneut oder checke manuell ein, indem du einen der Knöpfe drückst.",
    "Entry could not be saved, please log in and use the scanner you find in the dashboard.":
        "Ein Fehler ist aufgetreten. Bitte versuche es mit dem QR-Scanner im Dashboard.",
    "You are already checked in here, go back to the start and scan the checkpoint to start a new lap.":
        "Du hast diese Runde bereits abgeschlossen. Scanne den QR-Code bei der Talstation, um eine neue Runde zu starten.",
    "You are already checked in here, run to the top and scan the code there to finish the lap.":
        "Du hast bereits eine Runde gestartet. Scanne den QR-Code an der Bergstation, um die aktuelle Runde zu beenden.",
    "Scan a QR code to start or end a lap.":
        "Scanne den QR-Code bei der Tal- oder Bergstation der Magglingenbahn, um eine Runde zu starten / beenden. Falls du Probleme beim scannen hast, kannst du auch den Knopf benutzen um dich manuell einzuchecken.",
    "Station bottom": "Check-In Talstation",
    "Station top": "Check-Out Bergstation",
    "No entries found": "Du hast noch keine Einträge",
    "New": "Neu",
    "New sponsoring": "Neues Sponsoring",
    "Total": "Total",
    "Scanner": "Scanner",
    "The event has not started yet": "Der Event hat noch nicht begonnen.",
    "Check-In Instructions":
        "Du befindest dich bei der Talstation oder Bergstation und möchtest eine Runde starten bzw. beenden? Klicke auf den untenstehenden Button und wähle im Popup das erscheint «Zulassen». Anschliessend kannst du den QR-Code auf der Tafel scannen um ein- oder auszuchecken.",
    "Check-In Success Message":
        "Du bist jetzt eingecheckt. Wir wünschen dir viel Spass! Und: Bitte vergiss nicht, bei der Bergstation wieder auszuchecken, damit deine Runde gezählt wird ;-)",
    "Check-Out Success Message":
        "Gratulation! Du bist jetzt ausgecheckt und deine Runde wurde erfolgreich aufgezeichnet. Wenn du noch eine Runde laufen möchtest, kannst du an der Talstation wieder einchecken.",
    "Your run": "Bist du bereit?",
    "The event is over, you can't enter more laps":
        "Der Event ist vorbei, es können keine weiteren Runden hinzugefügt werden.",
    "You need to check in first":
        "Scanne zuerst den QR-Code bei der Talstation, um eine Runde zu starten. Falls du Probleme beim scannen hast, kannst du auch den Knopf benutzen um dich manuell einzuchecken.",
    "To the dashboard": "zurück zum Dashboard",
    "Running total": "Gesammeltes Geld",
    "With your engagement, you have so far gathered the following sponsoring amount:":
        "Durch dein Engagement konntest du bisher den folgenden Sponsoringbetrag erlaufen: {amount}",
    "CHF {amount} per meter of altitude": "CHF {amount} pro Höhenmeter",
    "CHF {amount} fixed amount": "CHF {amount} fixer Betrag",
    "CHF {amount} per round": "CHF {amount} pro Runde",
    "You have finished rounds so far.":
        "Du hast noch keine Runde absolviert. | Du hast bis jetzt {count} Runde absolviert. | Du hast bis jetzt {count} Runden absolviert.",
    "Profile": "Profil",
    "Share link": "Link teilen",
    "Nothing found": "Nichts gefunden.",
    "Start typing to search for a group.":
        "Gib den Namen der zu suchenden Gruppe ein",
    "Are you taking part as a member of a group? Enter the name of the group below:":
        "Optional: Nimmst du als Mitglied einer Gruppe teil? Wähle unten den Namen deiner Gruppe aus, um ihr beizutreten oder schreibe an support@442.run um eine neue Gruppe zu erstellen. Eine Gruppe kann maximal sechs Mitglieder haben. Weitere Infos zum Radys Team Tropy findest du <a href='/de/radys-team-trophy' target='_blank'>hier.</a>",
    "The group already has 6 members.":
        "Diese Gruppe hat bereits 6 Mitglieder. Wähle eine andere Gruppe (oder gründe eine neue Gruppe).",
    "Group": "Gruppe",
    "You are not currently in a group":
        "Du bist derzeit nicht in einer Gruppe.",
    "Support / Contact": "Support / Kontakt",
    "Please update your e-mail": "Bitte überprüfe deine E-Mail Adresse",
    "An e-mail recently sent to [[e-mail]] has bounced. Here's why: [[reason]]":
        "Das E-Mail an [[e-mail]] konnte nicht zugestellt werden. Bitte überprüfe die E-Mail Adresse die du angegeben hast und korrigiere diese falls nötig. Details zum Fehler: [[reason]]",
    "An e-mail recently sent to [[e-mail]] has bounced.":
        "Das E-Mail an [[e-mail]] konnte nicht zugestellt werden.",
    "Try with a different e-mail": "Mit einer anderen E-Mail Adresse versuchen",
    "No file chosen": "Keine Datei ausgewählt",
    "Choose a file": "Datei wählen",
    "This field is required": "Dieses Feld ist erforderlich.",
    "This does not look like a valid e-mail":
        "Dies sieht nicht wie eine gültige E-Mail Adresse aus.",
    "Please wait, data is loading...": "Bitte warten, Daten werden geladen...",
    "Thanks for subscribing!": "Vielen Dank für deine Anmeldung!"
};
