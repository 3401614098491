<template>
    <form
        method="POST"
        :class="classes"
        @submit.prevent="submit()">
        <div class="form__field form__field--label-text-upper">
            <label for="newsletter-form-email-input">
                {{ $t("Newsletter") }}
            </label>
            <label
                v-if="!submitted"
                class="control"
                :class="{ 'control--filled': email }">
                <input
                    v-model="email"
                    type="text"
                    name="inf[1]"
                    data-inf-meta="1"
                    data-inf-error="Please give an email address"
                    required="required" />
                <span class="control__placeholder">E-Mail</span>
            </label>
            <div v-else>{{ $t("Thanks for subscribing!") }}</div>
        </div>
        <button
            v-if="!submitting && !submitted"
            class="form__submit">
            {{ $t("Subscribe") }}
        </button>
    </form>
</template>

<script>
    import FormMixin from "./FormMixin";
    import ApiService from "@/services/api.service";

    export default {
        name: "FormNewsletter",
        mixins: [FormMixin],
        data() {
            return {
                email: "",
                submitted: false,
                submitting: false
            };
        },
        methods: {
            submit() {
                ApiService.customRequest({
                    method: "POST",
                    url: "/newsletter/",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    data: {
                        email: this.email
                    }
                }).then(() => {
                    // Will always succeed
                    this.submitted = true;
                });
            }
        }
    };
</script>
<style scoped lang="scss">
    .form__submit {
        margin-top: 1.5rem;
    }
</style>
