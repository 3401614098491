<template>
    <div class="footer-info">
        <address class="footer-info__address">
            LIONS CLUB MAGGLINGEN<br />
            DISTRICT 102 WEST / OUEST
        </address>
        <section class="footer-info__socials">
            <span>
                {{ $t("Support / Contact") }}
            </span>
            <br />
            <a
                href="https://support.442.run/help/2149447889"
                target="_blank">
                support@442.run
            </a>
        </section>
    </div>
</template>

<script>
    export default {
        name: "TheSocialLinks"
    };
</script>

<style scoped lang="scss">
    @import "~@/styles/variables";

    .footer-info {
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        text-transform: uppercase;

        .footer-info__address {
            font-style: normal;
        }

        .footer-info__socials {
            a {
                &,
                &:hover,
                &:link,
                &:active,
                &:focus,
                &:visited {
                    text-decoration: none;
                    color: $color-primary;
                }
            }
        }
    }
</style>
